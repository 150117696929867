import React, {
  createContext,
  useContext,
  useCallback,
  useState,
  useEffect,
} from 'react';
import { v4 as uuid } from 'uuid';

import ToastContainer from '../components/ToastContainer';
import { IErrors } from '../utils/getValidationErrors';

import { lastComit } from '../utils/version';

export interface ToastMessage {
  id: string;
  type?: 'success' | 'error' | 'info';
  title: string;
  description?: string | IErrors[];
  direction?: string;
  time?: number;
  callback?: () => void | undefined;
}

interface ToastContextObject {
  addToast(message: Omit<ToastMessage, 'id'>): void;
  removeToast(id: string): void;
}

const ToastContext = createContext<ToastContextObject>(
  {} as ToastContextObject,
);

const ToastProvider: React.FC = ({ children }) => {
  const [messages, setMessages] = useState<ToastMessage[]>([]);
  const [from, setFrom] = useState('');

  const addToast = useCallback(
    ({
      type,
      title,
      description,
      direction = 'items-center',
      time = 5000,
      callback,
    }: Omit<ToastMessage, 'id'>) => {
      setFrom(direction);

      const id = uuid();

      const toast = {
        id,
        type,
        title,
        description,
        time,
        callback
      };

      setMessages((oldToast) => [...oldToast, toast]);
    },
    [],
  );

  const removeToast = useCallback((id: string) => {
    setMessages((toast) => toast.filter((message) => message.id !== id));
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', (event) => {
      if (event instanceof KeyboardEvent) {
        const key = event.key.toLowerCase();

        if (event.ctrlKey && event.shiftKey && event.altKey && key === 'v') {
          addToast({
            type: 'info',
            title: 'Versão',
            description: `Develop: ${lastComit.develop}<br/>Homolog: ${lastComit.homolog}`,
            time: 7000,
          });
        }
      }
    });
  }, [addToast]);

  return (
    <ToastContext.Provider value={{ addToast, removeToast }}>
      {children}
      <ToastContainer messages={messages} direction={from} />
    </ToastContext.Provider>
  );
};

function useToast(): ToastContextObject {
  const context = useContext(ToastContext);

  if (!context) {
    throw new Error('useToast must be used witnin an ToastProvider');
  }

  return context;
}

export { ToastProvider, useToast };
