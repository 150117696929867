import { atom } from 'recoil';
import { ILoadingProps } from '../../interfaces/loading';

export const loadingStoreState = atom({
  key: 'loadingStore',
  default: {
    active: false,
    info: '',
  } as ILoadingProps,
});
