/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

interface ScrollToTopProps extends RouteComponentProps {
  children: any;
}

const ScrollToTop = ({
  children,
  location: { pathname },
}: ScrollToTopProps) => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, [pathname]);

  return children || null;
};

export default withRouter(ScrollToTop);
