import React, { useCallback, useContext, useState, useEffect } from 'react';

import { IoMdCopy } from 'react-icons/io';
import Icons from '../SVG/Icons';

import { NewPixProps, useNewPix } from '../../hooks/pix';
import { useToast } from '../../hooks/toast';
import { useAuth } from '../../hooks/auth';

import { formatDate } from '../../utils/formatDate';
import  formatAmout  from '../../utils/formatAmout';

import { PixObject,  Pix } from "../../hooks/pixContext";

import { useHistory } from 'react-router-dom';

import aws from '../../services/aws';

import { delay } from '../../utils/delay';

import Sentry from '../../Instrumentation';

const NewPix: React.FC<NewPixProps> = ({ action }: NewPixProps) => {
  const history = useHistory();
  
  const pix = useContext(Pix);
  const [transaction, setTransaction] = useState({qr_code_url: '', expires_at: '', amount: 0, status:'unknown', qr_code: "00020101021226820014br.gov.bcb.pix2560pix.stone.com.br/pix/v2/57e3680c-30cb-4815-818e-416a44fb33525204000053039865406171.635802BR5925GIFTHY NETWORK SERVICOS D6014RIO DE JANEIRO62290525pacllp3ftr5irki1fk1htk65v63040070"});

  const { user } = useAuth();

  const { removePix } = useNewPix();
  const { addToast } = useToast();

  const [url] = useState(() => {
    return process.env.REACT_APP_SITE_URL_PRO;
  });

  const handleRemoveNewPix = useCallback(() => {
    removePix(false);
    history.push('/presentes');
  }, [removePix]);

  const getTextShared = useCallback(() => {

    const textSend = (transaction && transaction["qr_code"])

    return textSend;
  }, [transaction]);

  const handleClipboard = useCallback(async() => {

    const textShared = getTextShared();

    if (
      document.queryCommandSupported &&
      document.queryCommandSupported('copy')
    ) {
      const textarea = document.createElement('textarea');
      textarea.textContent = textShared;
      textarea.style.position = 'fixed';
      document.body.appendChild(textarea);
      textarea.select();
      try {
        document.execCommand('copy');
      } catch (error) {

      } finally {
        document.body.removeChild(textarea);

        addToast({
          title: 'Código Pix copiado com sucesso!',
          type: 'success',
        });

        // await delay(1000);
        // handleRemoveNewPix();
      }
    }
  }, [getTextShared, addToast]);

  useEffect(() => {
    if (pix){
      if (pix.data){
        if (pix.data["last_transaction"]){
          setTransaction(pix.data["last_transaction"] || { qr_code: ''} )
        } 
      }
    }
  }, [pix, setTransaction]);

  const delay = (ms: number) => {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }

  useEffect(() => {
    if (transaction && transaction["status"] === 'paid'){
    
      addToast({
        title: 'Transação concluída',
        type: 'success',
        description: 'Transação realizada com sucesso!',
        time: 3000,
      });

      handleRemoveNewPix();
    }
  }, [transaction, addToast, removePix, handleRemoveNewPix]);

  const updatePix = async (transaction: any) => {

    const payment_id = transaction && transaction["payment_id"];

    if (payment_id === null || payment_id === undefined) return;

    try {

      const stack = process.env.REACT_APP_ENV === 'production' ? '.' : '.dev.';

      const headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }

      const { data } = await aws.post(`https://app${stack}gifthy.com.br/pagarme/pix`, { "payment_id": payment_id }, { headers: headers, timeout: 30000 });
      setTransaction(data["last_transaction"]);
      
    } catch (error) {
      Sentry.captureException(error);
    }  
  }

  useEffect(() => {
    if (transaction && transaction["status"] === 'paid'){
      return;
    }

    const interval = setInterval(() => {
      updatePix(transaction)
    }, 5000);

    return () => clearInterval(interval);
  }, [transaction]);

  return (    
    <div
      className={`absolute top-15 left-0 ${
        action ? 'block' : 'hidden'
      } w-full h-screen z-900`}
    >
      <div className="flex items-center justify-center min-w-full min-h-full h-full z-900">
        <div
          className="bg-gifthy-black-200 bg-opacity-20 w-full h-full absolute top-0 left-0 z-900"
          style={{
            backdropFilter: 'blur(40px)',
            WebkitBackdropFilter: 'blur(40px)',
          }}
        />

        <div className="bg-white w-full md:w-109-75 h-modal-h rounded-t-31 md:rounded-tl-11 md:rounded-bl-11 md:rounded-tr-none relative flex items-center flex-col py-200 z-1000">
          <br />
          <br />
          <div className="relative flex flex-row">
            <div className="w-full md:w-auto px-3">
              <label className="block tracking-wide text-gray-700 text-xs font-bold mb-2">
              Valor:
              </label>
              <div className="appearance-none text-center block w-full bg-gray-200 text-gray-700 border rounded py-6 px-6 mb-3 leading-tight focus:outline-none focus:bg-white">
                {/* {transaction && formatAmout(Number(transaction["amount"])/100) || formatAmout(3090/100)} */}
                {transaction && formatAmout(Number(transaction["amount"])/100)}
              </div>
            </div>
            <div className="w-full md:w-auto px-3">
              <label className="block tracking-wide text-gray-700 text-xs font-bold mb-2">
              Pague até:  
              </label>
              <div className="appearance-none text-center block w-full bg-gray-200 text-gray-700 border rounded py-6 px-6 mb-3 leading-tight focus:outline-none focus:bg-white">
                {/* {transaction && formatDate(transaction["expires_at"], true) || formatDate('2020-09-20T00:00:00Z', true)} */}
                {transaction && formatDate(transaction["expires_at"], true)}
              </div>              
            </div>
          </div>

          <img className="w-full md:w-auto" src="/images/pix0.jpeg" />  
          {/* <img className="pix_qr_code sm:not-sr-only md:w-auto" src="https://api.pagar.me/core/v5/transactions/tran_e85ozk4SJSEZ2OBD/qrcode?payment_method=pix" />   */}
          <img className="pix_qr_code sm:not-sr-only md:w-auto" src={transaction && transaction["qr_code_url"]} />  

          {/* <div className="text-gifthy-green">
            {transaction && transaction["status"]}
          </div> */}

          <div className="flex flex-col w-full mt-2 px-8">
            
            {/* <div className="break-all peer block min-h-[auto] w-full rounded border-0 bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 peer-focus:text-primary data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-neutral-200 dark:placeholder:text-neutral-200 dark:peer-focus:text-primary [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0">
              {transaction && transaction["qr_code"] || "00020101021226820014br.gov.bcb.pix2560pix.stone.com.br/pix/v2/57e3680c-30cb-4815-818e-416a44fb33525204000053039865406171.635802BR5925GIFTHY NETWORK SERVICOS D6014RIO DE JANEIRO62290525pacllp3ftr5irki1fk1htk65v63040070"}
            </div> */}

            <div className="flex items-center justify-between w-full mt-2">
              <button
                type="button"
                onClick={() => handleClipboard()}
                className="font-bold uppercase w-full h-12 px-6 text-white transition-colors duration-150 bg-black rounded-lg focus:shadow-outline hover:bg-black"
              >
                {/* <IoMdCopy size={16} className="mr-2" /> */}
                Copiar Código Pix
              </button>
            </div>
          </div>
          <img className="w-full md:w-auto" src="/images/pix1.jpeg" />  

          {/* <button
            type="button"
            onClick={() => handleRemoveNewPix()}
            className="font-bold bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            Fechar
          </button> */}

          <button
            type="button"
            onClick={() => handleRemoveNewPix()}
            className="w-8 h-8 rounded-full border-2 border-gifthy-blue bg-white flex items-center justify-center absolute top-0 -mt-4 md:right-0 md:-mr-4"
          >
            <Icons name="clear" className="w-5 h-5 text-gifthy-blue" />
          </button>

        </div>
      </div>
    </div>
  );
};

export default NewPix;
