import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

import { b64DecodeUnicode } from '../utils/base64';
import Sentry  from '../Instrumentation';

const getToken = (): string | null => localStorage.getItem('@GIFTHY:Token');

const url = process.env.REACT_APP_API_PROD;

const headers = {
  'Content-Type': 'application/json',
  'Accept': 'application/json',
  'Cache-Control': 'no-cache',
  'Pragma': 'no-cache',
  'Expires': '0',
}

const api = axios.create({
  baseURL: url,
  headers: headers, 
  timeout: 30000
});

api.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    const token = getToken();

    const customConfig = { ...config };
    if (token) {
      customConfig.headers.Authorization = `Bearer ${token}`;
    }

    const transactionId = Math.random().toString(36).substr(2, 9);
    
    Sentry.setTag("transaction_id", transactionId);
    Sentry.setTag("request_id", transactionId);
    customConfig.headers['X-Transaction-ID'] = transactionId;

    return customConfig;
  },
  (error) => Promise.reject(error),
);

api.interceptors.response.use(
  (response): AxiosResponse => { 
    
    if (response?.data?.data){
      try {
        
        const code = response.data.data
        const decodedString = b64DecodeUnicode(code.substring(0, code.length - 4));
        response.data = JSON.parse(decodedString);
        
        return response;

      } catch (error) {
        
        // localStorage.removeItem('@GIFTHY:Token');
        // localStorage.removeItem('@GIFTHY:user');
        // sessionStorage.clear();
    
        // history.push('/login');
        // history.go(0);
      
      }
    }

    return response;
  },
  (error): Promise<any> => {
    // localStorage.removeItem('@GIFTHY:Token');
    // localStorage.removeItem('@GIFTHY:user');
    // sessionStorage.clear();

    // history.push('/login');
    // history.go(0);

    return Promise.reject(error);
  },
);

export default api;
