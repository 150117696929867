import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

const Instrumentation = {
    init: () => {
        Sentry.init({
            dsn:
              'https://d6a8de844fefc28e99e46a7df16c1793@o4505836042584064.ingest.sentry.io/4505836055822336',
            integrations: [new Integrations.BrowserTracing()],
            environment: process.env.REACT_APP_ENV || 'local',
          
            tracesSampleRate: 1.0,
          });

        return Sentry
        },
    client: Sentry,
}

Instrumentation.init();

export default Instrumentation.client;
