import React, { useContext, useCallback, useState } from 'react';

import { PixObject,  Pix } from "./pixContext";

import NewPix from '../components/PixChecker/new';

export interface NewPixProps {
  action: boolean;
}

const NewPixProvider: React.FC = ({ children }) => {
  const [active, setActive] = useState(false);
  const [data, setData] = useState({});

  const addPix = useCallback((status: boolean, data: any) => {
    setData(data);
    setActive(status);
  }, [setData, setActive]);

  const removePix = useCallback((status: boolean) => {
    setActive(status);
  }, [setActive]);

  return (
    <Pix.Provider value={{ addPix, removePix, data }}>
      {children}
      <NewPix action={active} />
    </Pix.Provider>
  );
};

function useNewPix(): PixObject {
  const context = useContext(Pix);

  if (!context) {
    throw new Error('usePix must be used within an NewPixProvider');
  }

  return context;
}

export { NewPixProvider, useNewPix };
