import React, { useCallback, useEffect, useRef, useState } from 'react';

import * as Yup from 'yup';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import { IoMdShare, IoLogoWhatsapp, IoMdCopy } from 'react-icons/io';
import Icons from '../SVG/Icons';
import Input from '../Input';

import { NewFriendsProps, useNewFriend } from '../../hooks/friends';
import { useToast } from '../../hooks/toast';
import { IFriendsAddProps } from '../../interfaces/friends';
import api from '../../services/api';
import { useAuth } from '../../hooks/auth';
import { normalizeStrings } from '../../utils/normalizeStrings';
import { IErrors } from '../../utils/getValidationErrors';
import Errors, { ErrorsResponseData } from '../Errors';

const NewFriends: React.FC<NewFriendsProps> = ({ action }) => {
  const formRef = useRef<FormHandles>(null);

  const { user } = useAuth();

  const { removeFriend } = useNewFriend();
  const { addToast } = useToast();

  const [errors, setErrors] = useState<
    Yup.ValidationError | ErrorsResponseData | IErrors[] | string | null
  >(null);
  const [titleError, setTitleError] = useState('Erro no cadastro');

  const [url] = useState(() => {
    let newUrl;
    if (process.env.REACT_APP_ENV === 'production')
      newUrl = process.env.REACT_APP_SITE_URL_PROD;
    else if (process.env.REACT_APP_ENV === 'development')
      newUrl = process.env.REACT_APP_SITE_URL_DEV;
    else if (process.env.REACT_APP_ENV === 'test')
      newUrl = process.env.REACT_APP_SITE_URL_DEV;

    return newUrl;
    
    // return process.env.REACT_APP_SITE_URL_PRO;
  });

  const handleRemoveNewFriend = useCallback(() => {
    removeFriend(false);
  }, [removeFriend]);

  const getTextShared = useCallback(() => {
    const site = `${url}${
      user &&
      `/amigos/${btoa(`${user.ID}`)}/${normalizeStrings(
        `${user.FirstName} ${user.LastName}`,
      )
        .toLowerCase()
        .split(' ')
        .join('-')}`
    }`;

    // const textSend = `Olá, seu(ua) amigo(a) ${user?.FirstName} ${user?.LastName} enviou este convite para que você possa participar com ele(a) da rede de presentes Gifthy. Acesse ${site} e junte-se a ele(a)!`;
    const textSend = `Olá, seu(ua) amigo(a) ${user?.FirstName} ${user?.LastName} enviou este CONVITE ESPECIAL para você participar com ele(a) da fase de pré-lançamento do Gifthy - Seu Assistente Virtual para Presentear. O Gifthy vai resolver TUDO para você no momento de dar um presente. Nunca mais esqueça datas comemorativas ou erre na escolha. Acesse ${site} e confira!`;

    return textSend;
  }, [url, user]);

  const handleChangeUrlShared = useCallback(() => {
    const urlApiWhatsapp = 'https://wa.me/?text=';
    const textSend = getTextShared();

    return `${urlApiWhatsapp}${textSend.replace(' ', '%20')}`;
  }, [getTextShared]);

  const handleClipboard = useCallback(() => {
    setErrors(null);
    const textShared = getTextShared();

    if (
      document.queryCommandSupported &&
      document.queryCommandSupported('copy')
    ) {
      const textarea = document.createElement('textarea');
      textarea.textContent = textShared;
      textarea.style.position = 'fixed';
      document.body.appendChild(textarea);
      textarea.select();
      try {
        document.execCommand('copy');
      } catch (error) {
        setTitleError('Erro ao copiar');
        setErrors('Um erro ocorreu ao copiar o link de compartilhamento!');
      } finally {
        document.body.removeChild(textarea);

        addToast({
          title: 'Link copiado com sucesso!',
          type: 'success',
        });
      }
    }
  }, [getTextShared, addToast]);

  const handleSubmit = useCallback(
    async (data: IFriendsAddProps) => {
      try {
        formRef.current?.setErrors({});
        setErrors(null);

        const dataSchema = {
          SendEmail: Yup.string()
            .email('Email inválido')
            .required('Email do seu amigo.'),
        };

        const schema = Yup.object().shape(dataSchema);

        await schema.validate(data, {
          abortEarly: false,
        });

        await api.post('/invateEmail', {
          Email: data.SendEmail,
          Link: `${url}/amigos/email/`,
        });

        addToast({
          title: 'Convite',
          type: 'success',
          description: 'Convite enviado com sucesso!',
        });

        formRef.current?.reset();

        removeFriend(false);
      } catch (error) {
        setTitleError('Erro na operação');
        setErrors(error);
      }
    },
    [addToast, removeFriend, url],
  );

  useEffect(() => {
    if (action) {
      const inputEmail = formRef.current?.getFieldRef('SendEmail');
      inputEmail.focus();
    }
  }, [action]);

  return (
    <div
      className={`absolute top-0 left-0 ${
        action ? 'block' : 'hidden'
      } w-full h-screen z-900`}
    >
      <div className="flex items-center justify-center min-w-full min-h-full h-full fixed z-900">
        <div
          className="bg-gifthy-black-200 bg-opacity-20 w-full h-full absolute top-0 left-0 z-900"
          style={{
            backdropFilter: 'blur(2px)',
            WebkitBackdropFilter: 'blur(2px)',
          }}
        />

        <div className="bg-white w-full md:w-109-75 h-111-50 rounded-t-31 md:rounded-tl-11 md:rounded-bl-11 md:rounded-tr-none relative flex items-center flex-col py-8 z-1000">
          <button
            type="button"
            onClick={() => handleRemoveNewFriend()}
            className="w-8 h-8 rounded-full border-2 border-gifthy-blue bg-white flex items-center justify-center absolute top-0 -mt-4 md:right-0 md:-mr-4"
          >
            <Icons name="clear" className="w-5 h-5 text-gifthy-blue" />
          </button>

          <h1 className="flex items-center w-full justify-center md:justify-start text-21 md:text-2xl text-gifthy-blue font-bold px-8">
            <Icons
              name="users"
              className="w-6 h-6 md:w-10 md:h-10 text-gifthy-orange mr-2"
            />
            Convide um amigo!
          </h1>

          <h2 className="text-base text-center md:text-left md:text-xl text-gifthy-black font-bold mt-8 px-8">
            Informe o e-mail do seu amigo para enviar um convite.
          </h2>

          <Form
            ref={formRef}
            onSubmit={handleSubmit}
            className="w-full mt-6 px-8"
          >
            <Input
              name="SendEmail"
              type="text"
              iconLeft="mail"
              placeholder="Digite o e-mail do seu amigo"
              border="border-2 border-gifthy-black-200 rounded-md"
              className="px-4 py-4"
            />

            <button
              type="submit"
              className="flex w-full items-center justify-center bg-gifthy-orange text-sm text-white font-bold rounded-full py-5 mt-4"
            >
              Enviar convite
            </button>
          </Form>

          <div className="w-full border-t border-gifthy-gray-100 mt-8" />

          <div className="flex flex-col w-full mt-2 px-8">
            <h2 className="flex items-center justify-center text-sm text-gifthy-black font-bold">
              <IoMdShare size={14} className="mr-2" />
              Compartilhe seu convite
            </h2>

            <div className="flex items-center justify-between w-full mt-2">
              <a
                href={handleChangeUrlShared()}
                className="flex items-center p-2 text-sm text-gifthy-green font-bold w-full"
                target="_blank"
                rel="noopener noreferrer"
              >
                <IoLogoWhatsapp size={16} className="mr-2" />
                Whatsapp
              </a>

              <button
                type="button"
                onClick={() => handleClipboard()}
                className="flex items-center text-13 text-gifthy-blue font-bold p-2 w-full h-full justify-end"
              >
                <IoMdCopy size={16} className="mr-2" />
                Copiar link
              </button>
            </div>
          </div>
        </div>
      </div>

      <Errors title={titleError} errors={errors} time={5000} />
    </div>
  );
};

export default NewFriends;
