// const b64DecodeUnicode = (str: string): string => {
//     return decodeURIComponent(atob(str).split('').map(function(c) {
//         return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
//     }).join(''));
// };

// const b64EncodeUnicode = (str: any) {
//     return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
//         function toSolidBytes(match, p1) {
//             return String.fromCharCode('0x' + p1);
//     }));
// }

export const b64EncodeUnicode = (str: string): string => {
	return btoa(unescape(encodeURIComponent(str)));
}

export const b64DecodeUnicode = (str: string): string => {
	return decodeURIComponent(escape(atob(str)));
}
