import { atom } from 'recoil';
import { getImage } from '../../data';
import { UserDataProps } from '../../interfaces/users';

const setDefaultLocalStorage = (key: string): string | UserDataProps => {
  const local = localStorage.getItem(`@GIFTHY:${key}`);

  if (local) {
    if (key === 'user') return JSON.parse(local);

    return local;
  }

  const user = {} as UserDataProps;

  return key === 'user' ? user : '';
};

const setUserPhotoLocalStorage = (): string | undefined => {
  const local = localStorage.getItem('@GIFTHY:user');

  if (local) {
    const user: UserDataProps = JSON.parse(local);

    return user.Photo && getImage(`${user.Photo.Identifier}`);
  }

  return undefined;
};

export const userStore = atom({
  key: 'userState',
  default: setDefaultLocalStorage('user') as UserDataProps,
});

export const tokenStore = atom({
  key: 'tokenState',
  default: setDefaultLocalStorage('Token'),
});

export const userPhotoStore = atom({
  key: 'userPhotoState',
  default: setUserPhotoLocalStorage(),
});
