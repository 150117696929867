import { selector } from 'recoil';
import { getImage } from '../../data';

import { tokenStore, userStore } from './atom';

export const isLoggedStore = selector({
  key: 'isLoggedStore',
  get: ({ get }) => {
    const user = get(userStore);
    const userLength = Object.keys(user).length;

    const token = get(tokenStore);

    return userLength > 0 && token !== '';
  },
});

export const photoUserStore = selector({
  key: 'photoUserState',
  get: ({ get }) => {
    const user = get(userStore);
    const userLocal = localStorage.getItem('@GIFTHY:user');
    let photoID = user.Photo && getImage(`${user.Photo.Identifier}`);
    if (userLocal) {
      const userLocalParse = JSON.parse(userLocal);

      if (userLocalParse.Photo) {
        photoID = getImage(`${userLocalParse.Photo.Identifier}`);
      }
    }

    return photoID;
  },
});
