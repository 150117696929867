import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import {
  assistantSmallStateStore,
  assistantStateStore,
  bubbleStepStateStore,
  focusSearchFriendsStateStore,
  nameSmallAssistantStateStore,
} from '../../store/Assistant/atom';

import Icons from '../SVG/Icons';

const AssistantSmall: React.FC = () => {
  const [time] = useState(3600000);
  const [assistantSmallState, setAssistantSmallState] = useRecoilState(
    assistantSmallStateStore,
  );

  const [assistantState, setAssistantState] = useRecoilState(
    assistantStateStore,
  );

  const bubble = useRecoilValue(bubbleStepStateStore);

  const nameSmallAssistantState = useRecoilValue(nameSmallAssistantStateStore);
  const setFocusSearchFriendsState = useSetRecoilState(
    focusSearchFriendsStateStore,
  );

  const [noActive, setNoActive] = useState(true);
  useEffect(() => {
    const localAssistant = localStorage.getItem(nameSmallAssistantState);

    let open = true;

    if (localAssistant) {
      const assistant = JSON.parse(localAssistant);

      if (!assistant[bubble.step]) {
        open = false;
      }
    } else open = false;

    setNoActive(open);
  }, [nameSmallAssistantState, bubble.step]);

  const openInterval = useRef<any>();

  const openAssistant = useCallback(() => {
    setAssistantSmallState((old) => ({ ...old, active: true }));
  }, [setAssistantSmallState]);

  const handleClose = useCallback(() => {
    setAssistantSmallState((old) => ({ ...old, active: false }));
    setNoActive(true);
    setFocusSearchFriendsState(true);

    const localAssistant = localStorage.getItem(nameSmallAssistantState);

    if (localAssistant) {
      const assistant = JSON.parse(localAssistant);

      if (!assistant[bubble.step]) {
        const newAssistant = { ...assistant, [bubble.step]: true };

        localStorage.setItem(
          nameSmallAssistantState,
          JSON.stringify(newAssistant),
        );
      }
    } else {
      const assistant = { [bubble.step]: true };
      localStorage.setItem(nameSmallAssistantState, JSON.stringify(assistant));
    }

    clearInterval(openInterval.current);
  }, [
    setAssistantSmallState,
    bubble,
    nameSmallAssistantState,
    setFocusSearchFriendsState,
  ]);

  const handleOpenAssistant = useCallback(() => {
    setAssistantState((old) => ({ ...old, active: true }));
    setAssistantSmallState((old) => ({ ...old, active: false }));
  }, [setAssistantState, setAssistantSmallState]);

  useEffect(() => {
    if (!assistantSmallState.active && !assistantState.active && !noActive) {
      setFocusSearchFriendsState(false);
      openInterval.current = setInterval(() => openAssistant(), time);
    }

    document.addEventListener('mousemove', () => {
      clearInterval(openInterval.current);

      if (!assistantSmallState.active && !assistantState.active && !noActive) {
        setFocusSearchFriendsState(false);
        openInterval.current = setInterval(() => openAssistant(), time);
      }
    });
    document.addEventListener('keypress', () => {
      clearInterval(openInterval.current);

      if (!assistantSmallState.active && !assistantState.active && !noActive) {
        setFocusSearchFriendsState(false);
        openInterval.current = setInterval(() => openAssistant(), time);
      }
    });
  }, [
    openAssistant,
    assistantSmallState.active,
    assistantState.active,
    noActive,
    setFocusSearchFriendsState,
    time,
  ]);

  useEffect(() => {
    if (assistantState.active) clearInterval(openInterval.current);
  }, [assistantState.active]);

  useEffect(() => {
    if (noActive) clearInterval(openInterval.current);
  }, [noActive]);

  return (
    <>
      {assistantSmallState.active && (
        <div className="w-full h-screen flex items-center justify-center fixed top-0 left-0 bg-gifthy-black-100 bg-opacity-40 z-1100">
          <div
            className={`flex items-center justify-center flex-col px-5 ${assistantSmallState.color.background} w-40 h-40 rounded-full shadow-lg z-1000 relative`}
          >
            <div className="flex items-center justify-between flex-col w-full h-28">
              <h1
                className={`text-xl font-semibold ${assistantSmallState.color.heading} text-center`}
              >
                {assistantSmallState.heading}
              </h1>

              <button
                type="button"
                className={`flex items-center justify-center ${assistantSmallState.button.color.background} ${assistantSmallState.button.color.text} text-sm font-bold w-10/12 px-2 py-2 rounded-31 shadow-md`}
                onClick={() => handleOpenAssistant()}
              >
                {assistantSmallState.button.text}
              </button>
            </div>
            <div
              className={`flex items-center justify-center w-10 h-10 absolute bottom-0 right-0 mt-6 -mr-3 rounded-full ${assistantSmallState.color.background} ${assistantSmallState.color.heading} shadow-bottom`}
            >
              <button type="button" onClick={() => handleClose()}>
                <Icons name="fa-times-solid" className="text-white w-4 h-4" />
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AssistantSmall;
