/* eslint-disable @typescript-eslint/no-empty-function */
import React, {
  useEffect,
  useCallback,
  useRef,
  useState,
  useMemo,
} from 'react';
import { useField } from '@unform/core';
import Icons from '../SVG/Icons';

// interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
interface Props<T> {
  type?: string;
  name: string;
  textColor?: string;
  placeholderColor?: string;
  paddingX?: string;
  paddingYInput?: string;
  className?: string;
  border?: string;
  classLabel?: string;
  label?: string;
  classLabelSpan?: string;
  iconLeft?: string;
  iconRight?: string;
  eyePassword?: boolean;
  multiline?: boolean;
  index?: number;
  currency?: boolean;
  maxValue?: number;
  purchasePrice?: number;
  handleChangeWalletAmount?: (amount: number) => void;
  handleChangeAmount?: (amount: number) => void;
  handleChangeQuantity?: (quantity: number) => void;
  event?: boolean;
  enter?: boolean;
  handleSubmitEnter?: () => void;
  nextFocus?: boolean;
}

type InputProps = JSX.IntrinsicElements['input'] & Props<true>;
type TextAreaProps = JSX.IntrinsicElements['textarea'] & Props<false>;

const Input: React.FC<InputProps | TextAreaProps> = ({
  type = 'text',
  name,
  textColor = 'text-gifthy-black-500',
  placeholderColor = 'placeholder-gifthy-black-300',
  paddingX,
  paddingYInput = 'py-2',
  className,
  border = 'border-b-2 border-gifthy-black-300',
  classLabel = '',
  label = '',
  classLabelSpan = 'text-sm font-bold text-gifthy-blue uppercase',
  iconLeft,
  iconRight,
  eyePassword,
  multiline = false,
  index,
  currency = false,
  maxValue = 9999999999,
  purchasePrice = 9999999999,
  handleChangeWalletAmount = () => {},
  handleChangeAmount = () => {},
  handleChangeQuantity = () => {},
  event = false,
  enter = false,
  handleSubmitEnter = () => {},
  nextFocus = false,
  ...rest
}: InputProps | TextAreaProps) => {
  const ref = useRef<HTMLInputElement | HTMLTextAreaElement>(null);

  const { fieldName, defaultValue, error, registerField } = useField(name);

  const [typeInput, setTypeInput] = useState(type);

  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(true);

  const styleFocused = isFocused
    ? `${
        error
          ? 'border-gifthy-google text-gifthy-google'
          : `border-gifthy-black-500 ${textColor}`
      }`
    : textColor;

  const styleText = isFilled && !isFocused ? textColor : styleFocused;

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(
    (e) => {
      setIsFocused(false);

      setIsFilled(!!e.target.value);

      if (event) {
        const sessionEvent = sessionStorage.getItem('@GIFTHY:event');

        if (sessionEvent) {
          const newSessionEvent = JSON.parse(sessionEvent);
          newSessionEvent[name] = e.target.value;

          sessionStorage.setItem(
            '@GIFTHY:event',
            JSON.stringify(newSessionEvent),
          );
        } else {
          sessionStorage.setItem(
            '@GIFTHY:event',
            `{"${name}": "${e.target.value}"}`,
          );
        }
      }
    },
    [event, name],
  );

  const props = {
    ...rest,
    ref,
    id: fieldName,
    name: fieldName,
    'aria-label': fieldName,
    defaultValue,
  };

  const handleEyePassword = useCallback(() => {
    const newType = typeInput === 'password' ? 'text' : 'password';
    setTypeInput(newType);
  }, [typeInput]);

  const handleChange = useCallback(
    (e) => {
      if (currency) {
        if (e.target.value.length <= 1) {
          e.target.value = `0${e.target.value}`;
        }

        let atual = +parseFloat(
          e.target.value.replace(/[\D]+/g, '').replace(/(\d\d?)$/, '.$1'),
        ).toFixed(2);

        if (atual > maxValue) {
          atual = maxValue;
        }

        if (atual > purchasePrice) {
          atual = purchasePrice;
        }

        if (name === 'WalletAmount') {
          handleChangeWalletAmount && handleChangeWalletAmount(atual);
        }

        if (name === 'Amount') {
          handleChangeAmount && handleChangeAmount(Number(atual));
        }

        e.target.value = atual.toLocaleString('pt-br', {
          style: 'currency',
          currency: 'BRL',
        });
      } else if (name === 'Quantity') {
        handleChangeQuantity && handleChangeQuantity(Number(e.target.value));
      }
    },
    [
      currency,
      maxValue,
      purchasePrice,
      handleChangeWalletAmount,
      name,
      handleChangeQuantity,
      handleChangeAmount,
    ],
  );

  const handleKeyUp = useCallback(
    (e) => {
      if (e.target.validity.valid) {
        if (enter) {
          if (e.which === 13) handleSubmitEnter && handleSubmitEnter();
        }
  
        if (nextFocus) {
          if (e.which === 13) {
            if (ref.current) {
              let nextInput = document.querySelectorAll(
                `[tabIndex="${ref.current.tabIndex + 1}"]`,
              );
  
              if (!nextInput.length)
                nextInput = document.querySelectorAll('[tabIndex="1"]');
  
              const next = nextInput[0] as HTMLInputElement;
              next.focus();
            }
          }
        }
      } else {
        if (name !== 'LoginEmail' && name !== 'Email') {
          e.target.value = 1;
          e.preventDefault();
        }
      }

      // if (e.which < 48 || e.which > 57){
      //     e.preventDefault();
      //     e.target.value = 0;
      // }

      // if (enter) {
      //   if (e.which === 13) handleSubmitEnter && handleSubmitEnter();
      // }

      // if (nextFocus) {
      //   if (e.which === 13) {
      //     if (ref.current) {
      //       let nextInput = document.querySelectorAll(
      //         `[tabIndex="${ref.current.tabIndex + 1}"]`,
      //       );

      //       if (!nextInput.length)
      //         nextInput = document.querySelectorAll('[tabIndex="1"]');

      //       const next = nextInput[0] as HTMLInputElement;
      //       next.focus();
      //     }
      //   }
      // }
    },
    [enter, handleSubmitEnter, nextFocus],
  );

  useEffect(() => {
    if (ref.current) {
      registerField({ name: fieldName, ref: ref.current, path: 'value' });
    }
  }, [fieldName, registerField]);

  const paddingXValue = useMemo(() => {
    if (paddingX) return paddingX;
    if (label === '') return 'px-3';

    return 'pr-3';
  }, [paddingX, label]);

  const nameIcon = useCallback((icon) => {
    return icon;
  }, []);

  return (
    <div className={classLabel}>
      {label !== '' && <span className={classLabelSpan}>{label}</span>}

      <div
        className={`
          flex
          items-center
          justify-between
          ${border}
          ${paddingXValue}
          fill-current
          ${className}
          ${styleText}
        `}
      >
        {iconLeft && <Icons name={nameIcon(iconLeft)} className="w-5 h-5" />}

        {multiline ? (
          <textarea
            className={`w-full h-full text-sm font-semibold bg-transparent ${placeholderColor} ${styleText}`}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            tabIndex={index}
            {...(props as TextAreaProps)}
          />
        ) : (
          <input
            className={`w-full ${paddingXValue} ${paddingYInput} bg-transparent text-sm font-semibold ${placeholderColor} ${styleText}`}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            onChange={(e) => handleChange(e)}
            onKeyUp={(e) => handleKeyUp(e)}
            tabIndex={index}
            {...(props as InputProps)}
            type={typeInput}
          />
        )}
        {iconRight && <Icons name={nameIcon(iconRight)} className="w-5 h-5" />}

        {eyePassword && (
          <button
            type="button"
            onClick={() => handleEyePassword()}
            onFocus={() => handleEyePassword()}
            className="focus:outline-none w-5 h-5"
            tabIndex={-1}
          >
            <Icons
              name={nameIcon(
                `ionic-eye${typeInput === 'text' ? '-close' : ''}`,
              )}
              className="w-5 h-5"
            />
          </button>
        )}
      </div>
    </div>
  );
};

export default Input;
