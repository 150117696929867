import { ValidationError } from 'yup';

export interface IErrors {
  [key: string]: string;
}

const getValidationErrors = (err: ValidationError): IErrors => {
  const validationErrors: IErrors = {};

  err.inner.forEach((error) => {
    validationErrors[error.path] = error.message;
  });

  return validationErrors;
};

const getValidationErrorsArray = (err: ValidationError): IErrors[] => {
  const validationErrors: IErrors[] = [];

  err.inner.forEach((error, i) => {
    validationErrors.push({ id: `${error.path}-${i}`, message: error.message });
  });

  return validationErrors;
};

export { getValidationErrors, getValidationErrorsArray };
