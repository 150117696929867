import React, { createContext, useCallback, useState, useContext } from 'react';

import { useSetRecoilState } from 'recoil';

import {
  UserDataProps,
  PatchUserDataProps,
  LoginFormData,
} from '../interfaces/users';
import api from '../services/api';
import { formatDate } from '../utils/formatDate';
import { PhotoDataProps } from '../interfaces/photos';

import {
  nameLargeAssistantStateStore,
  nameSmallAssistantStateStore,
} from '../store/Assistant/atom';

interface AuthState {
  Token: string;
  user: UserDataProps;
}

interface RecoverPassword {
  Email: string;
}

interface ResetPassword {
  Token: string;
  Password: string;
}

interface AuthContextObject {
  user: UserDataProps;
  login(credentials: LoginFormData): Promise<void>;
  logout(): void;
  resetPassword(credentials: ResetPassword): Promise<void>;
  recoverPassword(email: RecoverPassword): Promise<void>;
  patchUser(newUser: PatchUserDataProps): Promise<void>;
  patchAvatarUser(photo: PhotoDataProps): void;
}

type AuthProps = {
  // eslint-disable-next-line react/require-default-props
  children?: React.ReactNode;
};

const AuthContext = createContext<AuthContextObject>({} as AuthContextObject);

const AuthProvider: React.FC<AuthProps> = ({ children }: AuthProps) => {
  const setNameLargeAssistantState = useSetRecoilState(
    nameLargeAssistantStateStore,
  );
  const setNameSmallAssistantState = useSetRecoilState(
    nameSmallAssistantStateStore,
  );

  const [data, setData] = useState<AuthState>(() => {
    const Token = localStorage.getItem('@GIFTHY:Token');
    const user = localStorage.getItem('@GIFTHY:user');

    if (Token && user) {
      const userLogged = JSON.parse(user);
      setNameLargeAssistantState(`@GIFTHY:assistantLarge:${userLogged.ID}`);
      setNameSmallAssistantState(`@GIFTHY:assistantSmall:${userLogged.ID}`);

      return { Token, user: userLogged };
    }

    return {} as AuthState;
  });

  const login = useCallback(
    async ({
      LoginEmail: email,
      LoginPassword: password,
    }: LoginFormData): Promise<void> => {
      const responseToken = await api.post('/login', {
        Email: email,
        Password: password,
      });

      const { Token } = responseToken.data;

      const responseUser = await api.get('/current', {
        headers: { Authorization: `Bearer ${Token}` },
      });

      const {
        ID,
        FirstName,
        LastName,
        Birthday,
        NickName,
        PagarmeId,
        Email,
        CPF,
        Photo,
        ShowYearOfBirth,
        BlockEmails,
        Addresses,
        SupplierId,
        Admin,
      } = responseUser.data;

      const user = {
        ID,
        FirstName,
        LastName,
        Birthday: formatDate(Birthday, false),
        NickName,
        PagarmeId,
        Email,
        CPF,
        Photo,
        ShowYearOfBirth,
        BlockEmails,
        Addresses,
        SupplierId,
        Admin
      };

      localStorage.setItem('@GIFTHY:Token', Token);
      localStorage.setItem('@GIFTHY:user', JSON.stringify(user));

      setNameLargeAssistantState(`@GIFTHY:assistantLarge:${user.ID}`);
      setNameSmallAssistantState(`@GIFTHY:assistantSmall:${user.ID}`);

      setData({ Token, user });
    },
    [setNameLargeAssistantState, setNameSmallAssistantState],
  );

  const logout = useCallback(() => {
    const site = process.env.REACT_APP_SITE_URL_PROD;

    localStorage.removeItem('@GIFTHY:Token');
    localStorage.removeItem('@GIFTHY:user');

    sessionStorage.clear();

    setData({} as AuthState);

    window.location.href = `${site}login`;
  }, []);

  const recoverPassword = useCallback(async (email) => {
    const { Email } = email;
    await api.post('/reset-password', { Email });
  }, []);

  const resetPassword = useCallback(async ({ Token, Password }) => {
    await api.post('/reset-password/confirm', { Token, Password });
  }, []);

  const patchUser = useCallback(async (newUser: PatchUserDataProps) => {
    const userPhoto = localStorage.getItem('@GIFTHY:user');
    const photo = userPhoto && JSON.parse(userPhoto);

    await api
      .patch('/current', newUser)
      .then((response) => {
        const {
          ID,
          FirstName,
          LastName,
          Birthday,
          NickName,
          PagarmeId,
          Email,
          CPF,
          ShowYearOfBirth,
          BlockEmails,
          Addresses,
          SupplierId,
          Admin,
        } = response.data;

        const user = {
          ID,
          FirstName,
          LastName,
          Birthday: formatDate(Birthday, false),
          NickName,
          PagarmeId,
          Email,
          CPF,
          Photo: photo.Photo,
          ShowYearOfBirth,
          BlockEmails,
          Addresses,
          SupplierId,
          Admin
        };

        localStorage.setItem('@GIFTHY:user', JSON.stringify(user));

        const Token = localStorage.getItem('@GIFTHY:Token') || '';
        setData({ Token, user });

        return user;
      })
      .catch((error) => {
        throw Error(error.response ? error.response.data.error : error);
      });
  }, []);

  const patchAvatarUser = useCallback((photo: PhotoDataProps) => {
    const Token = localStorage.getItem('@GIFTHY:Token');
    const user = localStorage.getItem('@GIFTHY:user');

    if (Token && user) {
      const newUser = JSON.parse(user);

      newUser.Photo = photo;

      localStorage.setItem('@GIFTHY:user', JSON.stringify(newUser));

      setData({ Token, user: newUser });
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user: data.user,
        login,
        logout,
        recoverPassword,
        resetPassword,
        patchUser,
        patchAvatarUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextObject {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used sithin an AuthProvider');
  }

  return context;
}

export { AuthProvider, useAuth };
