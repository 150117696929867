import { format } from "date-fns";

type ParamsProps = {
  date?: boolean;
  time?: boolean;
};

type DateFormatProps = {
  date?: string;
  params?: ParamsProps;
  locale?: string;
};

type OptionsProps = {
  [index: string]: string | boolean;
};


export const formatBirthDate = (dateString : any) => {
  const date = new Date(dateString);
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

const dateTimeFormat = ({
  date = '',
  params = {},
  locale = 'en-US',
}: DateFormatProps): string => {
  if ([true, null, ''].includes(date)) return '';

  let formattedDate = '';
  const numeric = ['year', 'month', 'day', 'hour', 'minute', 'second'];

  // default for params
  const nDate = params.date !== undefined ? params.date : true;
  const nTime = params.time !== undefined ? params.time : true;

  // merge default param values with method argument params
  const options = {
    year: nDate,
    month: nDate,
    day: nDate,
    hour: nTime,
    minute: nTime,
    second: nTime,
    locale,
    hour12: false,
    timeZone: 'UTC',
    ...params,
  } as OptionsProps;

  // set or remove attrs from options
  numeric.forEach((el) => {
    if (options[el]) options[el] = 'numeric';
    else delete options[el];
  });

  try {
    const d = new Date(date);
    formattedDate = new Intl.DateTimeFormat(
      options.locale.toString(),
      options,
    ).format(d);
  } catch (err) {
    formattedDate = 'invalid date';
  }
  
  if (formattedDate) {
    const dayTime = formattedDate.split(', ');
    const day = dayTime[0].split('/');
    if (dayTime[1]) {
      const time = dayTime[1].split(':');
      return `${day[0]}/${day[1]}, ${time[0]}:${time[1]}`;
    }
    return formattedDate;
  }

  return formattedDate;
};

export const formatDate = (value: string, time: boolean): string => {
  const newValue = time
    ? dateTimeFormat({
        date: value,
        params: { date: true, time },
        locale: 'pt-BR',
      })
    : dateTimeFormat({
        date: value,
        params: { date: true, time },
        locale: 'pt-BR',
      });

  return newValue;
};

interface DateBirthdayProps {
  value: string;
  day?: string;
  month?: string;
}
export const formatDateBirthday = ({
  value,
  day = '2-digit',
  month = 'short',
}: DateBirthdayProps): string => {
  const newValue = new Date(value);

  const newDay = new Intl.DateTimeFormat('pt-BR', {
    day,
    timeZone: 'UTC',
  }).format(newValue);

  const newMonth = new Intl.DateTimeFormat('pt-BR', {
    month,
    timeZone: 'UTC',
  }).format(newValue);

  const dayMonth = `${newDay} de ${newMonth
    .charAt(0)
    .toUpperCase()}${newMonth.slice(
    1,
    month === 'long' ? newMonth.length : newMonth.length - 1,
  )}`;

  return dayMonth;
};

export const formatYourAge = (value: string): string => {
  const today = new Date();
  const currentYear = today.getFullYear();

  const birthday = new Date(value);
  const birthdayYear = birthday.getFullYear();

  return `${currentYear - birthdayYear}`;
};

export const reverseDate = (value: string): string => {
  try {
    const newArray = value.split('/');

    const newValue = newArray.reverse().join('/');

    const newDate = new Date(newValue);

    return newDate.toISOString();
  } catch (err) {
    return 'false';
  }
};

export const addDays = (date: Date, days: number): Date => {
  let result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

export const getManyDaysUntil = (value: string): number => {
  const today = new Date();
  const dayCompare = new Date(value);

  const dayUntil = Math.floor(
    (Date.UTC(dayCompare.getFullYear(), dayCompare.getMonth(), dayCompare.getDate()) -
      Date.UTC(today.getFullYear(), today.getMonth(), today.getDate())) /
      (1000 * 60 * 60 * 24)
  );

  return Math.abs(dayUntil);
};
