import React, { useCallback, useMemo } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import {
  assistantStateStore,
  bubbleStepStateStore,
  focusSearchFriendsStateStore,
  nameLargeAssistantStateStore,
} from '../../store/Assistant/atom';
import {
  headingBubbleSelectorStore,
  textBubbleSelectorStore,
  linkTextBubbleSelectorStore,
} from '../../store/Assistant/selectors';
import { scrollStore } from '../../store/Scroll/atom';
import Icons from '../SVG/Icons';
import Logos from '../SVG/Logos';

const Assistant: React.FC = () => {
  const { pathname } = useLocation();

  const [assistantState, setAssistantState] = useRecoilState(
    assistantStateStore,
  );

  const bubble = useRecoilValue(bubbleStepStateStore);

  const heading = useRecoilValue(headingBubbleSelectorStore);
  const text = useRecoilValue(textBubbleSelectorStore);
  const linkText = useRecoilValue(linkTextBubbleSelectorStore);

  const nameLargeAssistantState = useRecoilValue(nameLargeAssistantStateStore);

  const setFocusSearchFriendsState = useSetRecoilState(
    focusSearchFriendsStateStore,
  );

  const setScrollState = useSetRecoilState(scrollStore);

  const handleClose = useCallback(
    (onlyClose = false) => {
      setAssistantState((old) => ({ ...old, active: false }));

      if (bubble.step === 'friends' && !onlyClose)
        setFocusSearchFriendsState(true);

      if (bubble.step === 'products') setScrollState({ x: 0, y: 450 });
      // else if (bubble.step === 'dashboard') setScrollState({ x: 0, y: 900 });
      else setScrollState({ x: 0, y: 0 });

      const localAssistant = localStorage.getItem(nameLargeAssistantState);

      if (localAssistant) {
        const assistant = JSON.parse(localAssistant);

        if (!assistant[bubble.step]) {
          const newAssistant = { ...assistant, [bubble.step]: true };
          localStorage.setItem(
            nameLargeAssistantState,
            JSON.stringify(newAssistant),
          );
        }
      } else {
        const assistant = { [bubble.step]: true };
        localStorage.setItem(
          nameLargeAssistantState,
          JSON.stringify(assistant),
        );
      }
    },
    [
      setAssistantState,
      bubble,
      nameLargeAssistantState,
      setFocusSearchFriendsState,
      setScrollState,
    ],
  );

  const isButton = useMemo(() => {
    if (assistantState.link.url === '/produtos' && pathname === '/produtos')
      return true;
    if (assistantState.link.url === '/amigos' && pathname === '/amigos')
      return true;

    return false;
  }, [assistantState, pathname]);

  return (
    <>
      {assistantState.active && (
        <div className="w-full h-screen flex items-center justify-center fixed top-0 left-0 bg-gifthy-black-100 bg-opacity-40 z-1100">
          <div
            className={`flex items-center justify-center flex-col px-5 py-5 sm:py-0 ${assistantState.color.background} w-66-50 sm:w-125 h-66-50 sm:h-125 lg:mt-20 rounded-full shadow-lg z-100`}
          >
            <div className="flex items-center justify-end w-2/3 h-4 -mb-4 md:mb-0">
              <button
                className="-mb-16 sm:mb-0"
                type="button"
                onClick={() => handleClose(true)}
              >
                <Icons name="fa-times-solid" className="text-white w-4 h-4" />
              </button>
            </div>

            <div className="mb-4 md:mb-8">
              <Logos type="favico" className="w-16 h-16" />
            </div>
            <div className="flex items-center justify-between flex-col w-full h-50 md:h-60-75">
              <h1
                className={`text-xl sm:text-2xl font-bold ${assistantState.color.heading}`}
              >
                {heading}
              </h1>

              <p
                className={`text-sm sm:text-lg ${assistantState.color.text} text-center font-medium`}
              >
                {text}
              </p>

              {isButton ? (
                <button
                  type="button"
                  className={`flex items-center justify-center ${assistantState.link.color.background} ${assistantState.link.color.text} text-lg font-bold w-7/12 px-2 py-2 rounded-31 shadow-md`}
                  onClick={() => handleClose()}
                >
                  <span className="text-sm sm:text-lg">{linkText}</span>
                </button>
              ) : (
                <NavLink
                  to={{
                    pathname: assistantState.link.url,
                    state: { from: pathname },
                  }}
                  className={`flex items-center justify-center ${assistantState.link.color.background} ${assistantState.link.color.text} text-lg font-bold w-7/12 px-2 py-2 rounded-31 shadow-md`}
                  onClick={() => handleClose()}
                >
                  <span className="text-sm sm:text-lg">{linkText}</span>
                </NavLink>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Assistant;
