import { createContext } from 'react';

export interface PixObject {
    addPix(status: boolean, data: any): void;
    removePix(status: boolean): void;
    data: any;
  }
  
const Pix = createContext<PixObject>({} as PixObject);

export { Pix };