import { selector } from 'recoil';

import {
  assistantStateStore,
  headingBubbleStateStore,
  textBubbleStateStore,
  bubbleStepStateStore,
} from './atom';

export const headingBubbleSelectorStore = selector({
  key: 'headingBubbleSelector',
  get: ({ get }) => {
    const heading = get(headingBubbleStateStore);
    const bubble = get(bubbleStepStateStore);

    return heading[bubble.step];
  },
});

export const textBubbleSelectorStore = selector({
  key: 'textBubbleSelector',
  get: ({ get }) => {
    const text = get(textBubbleStateStore);
    const bubble = get(bubbleStepStateStore);

    return text[bubble.step];
  },
});

export const linkTextBubbleSelectorStore = selector({
  key: 'linkTextBubbleSelector',
  get: ({ get }) => {
    const link = get(assistantStateStore);
    const bubble = get(bubbleStepStateStore);

    return link.link.text[bubble.step];
  },
});
