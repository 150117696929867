import React from 'react';
import ReactDOM from 'react-dom';

import './assets/css/main.css';
import App from './App';

import Sentry from './Instrumentation';

const FallbackComponent: React.FC = () => {
  return (
    <>
      {'Estamos Trabalhando para melhor atendê-lo, tente novamente mais tarde ou entre em contato com o suporte e informe o erro.'}
    </>
  )
}

ReactDOM.render(
  <Sentry.ErrorBoundary fallback={FallbackComponent} showDialog>
    <App />
  </Sentry.ErrorBoundary>
  , 
  document.getElementById('root')
);
