import React, { useEffect, Fragment } from 'react';
import {
  FiAlertCircle,
  FiCheckCircle,
  FiInfo,
  FiXCircle,
} from 'react-icons/fi';
import { animated } from 'react-spring';

import { ToastMessage, useToast } from '../../../hooks/toast';

interface ToastProps {
  message: ToastMessage;
  style: Record<string, unknown>;
}

const sizeIcon = 24;
const icons = {
  info: <FiInfo size={sizeIcon} />,
  success: <FiCheckCircle size={sizeIcon} />,
  error: <FiAlertCircle size={sizeIcon} />,
};

const Toast: React.FC<ToastProps> = ({ message, style }) => {
  const { removeToast } = useToast();

  useEffect(() => {
    const timer = setTimeout(() => {
      removeToast(message.id);
      if (message.callback) message.callback();
    }, message.time);

    return () => {
      clearTimeout(timer);
    };
  }, [removeToast, message]);

  return (
    <animated.div
      className={`${
        message.type === undefined ? '' : `toast-${message.type || 'info'}`
      }`}
      style={style}
    >
      {icons[message.type || 'info']}

      <div
        className={`${message.description === '' ? 'flex items-center' : ''}`}
      >
        <strong>{message.title}</strong>

        {message.description && (
          <>
            {typeof message.description === 'string' ? (
              <p dangerouslySetInnerHTML={{ __html: message.description }} />
            ) : (
              <>
                <p>{message.description[0].message}</p>
                <ul className="ml-4 list-disc">
                  {message.description.map((description, index) => {
                    if (index) {
                      return (
                        <li
                          key={`list-addtoast-${message.id}-${description.id}`}
                          className="mt-1"
                        >
                          {description.message}
                        </li>
                      );
                    }
                    return (
                      <Fragment
                        key={`list-addtoast-${message.id}-${description.id}`}
                      />
                    );
                  })}
                </ul>
              </>
            )}
          </>
        )}
      </div>

      <button
        onClick={() => removeToast(message.id)}
        type="button"
        className="focus:outline-none"
      >
        <FiXCircle size={18} />
      </button>
    </animated.div>
  );
};

export default Toast;
