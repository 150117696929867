import React, { lazy, Suspense } from 'react';
import { Switch, Route as RouteDOM } from 'react-router-dom';

import { AnimatePresence } from 'framer-motion';

import Route from './Route';

import Loading from '../components/Loading';

const Login = lazy(() => import('../pages/Login'));
const RecoverPassword = lazy(() => import('../pages/Login/recover'));
const ResetPassword = lazy(() => import('../pages/Login/reset'));
const Register = lazy(() => import('../pages/Register'));
const RegisterThank = lazy(() => import('../pages/Register/thank'));
const RegisterActivate = lazy(() => import('../pages/Register/activate'));
const Dashboard = lazy(() => import('../pages/Dashboard'));
const Friends = lazy(() => import('../pages/Friends'));
const FriendsDetail = lazy(() => import('../pages/Friends/detail'));
const Gifts = lazy(() => import('../pages/Gifts'));
const Parabens = lazy(() => import('../pages/Parabens'));
const Products = lazy(() => import('../pages/Products'));
const Events = lazy(() => import('../pages/Events'));
const NewEvents = lazy(() => import('../pages/Events/new'));
const EventsDetail = lazy(() => import('../pages/Events/detail'));
const Account = lazy(() => import('../pages/Account'));
const Notifications = lazy(() => import('../pages/Notifications'));
const Suppliers = lazy(() => import('../pages/Suppliers/suppliers'));
const ProductsDetail = lazy(() => import('../pages/Products/detail'));
const NotFoundPage = lazy(() => import('../pages/NotFoundPage'));

const Routes: React.FC = () => {
  return (
    <AnimatePresence>
      <Suspense fallback={<Loading />}>
        <Switch>
          <Route path="/login/:social?" exact component={Login} />
          <Route path="/recuperar-senha" exact component={RecoverPassword} />
          <Route path="/resetar/senha/:token" exact component={ResetPassword} />
          <Route path="/cadastre-se" exact component={Register} />
          <Route path="/cadastro/obrigado" exact component={RegisterThank} />
          <Route
            path="/confirmar-email/:token"
            exact
            component={RegisterActivate}
          />

          <Route path="/amigos" exact component={Friends} isPrivate />
          <Route
            path="/amigos/email/:token"
            exact
            component={FriendsDetail}
            isPrivate
          />
          <Route
            path="/amigos/:id/:name?"
            exact
            component={FriendsDetail}
            isPrivate
          />

          <Route path="/presentes" exact component={Gifts} isPrivate />

          <Route path="/desejos" exact component={Products} isPrivate />

          <Route path="/parabens-pedido-submetido" exact component={Parabens} isPrivate />

          <Route path="/produtos" exact component={Products} isPrivate />
          <Route
            path="/produtos/:id/:type?"
            exact
            component={ProductsDetail}
            isPrivate
          />

          <Route path="/eventos" exact component={Events} isPrivate />
          <Route path="/eventos/novo" exact component={NewEvents} isPrivate />
          <RouteDOM path="/eventos/inexistente" exact>
            <NotFoundPage type="eventos" />
          </RouteDOM>
          <RouteDOM path="/eventos/somente-para-amigos" exact>
            <NotFoundPage type="eventos-amigos" />
          </RouteDOM>
          <Route
            path="/eventos/editar/:id"
            exact
            component={NewEvents}
            isPrivate
          />
          <Route
            path="/eventos/detalhes/:id"
            exact
            component={EventsDetail}
            isPrivate
          />
          <Route
            path="/eventos/:id/:token/:name"
            exact
            component={EventsDetail}
            isPrivate
          />

          <Route
            path="/vaquinha/editar/:id"
            exact
            component={NewEvents}
            isPrivate
          />
          <Route
            path="/vaquinha/detalhes/:id"
            exact
            component={NewEvents}
            isPrivate
          />
          <Route
            path="/vaquinha/:id/:name"
            exact
            component={EventsDetail}
            isPrivate
          />
          <Route
            path="/vaquinha/:id"
            exact
            component={EventsDetail}
            isPrivate
          />
          <Route path="/vaquinha" exact component={NewEvents} isPrivate />

          <Route path="/minha-conta" exact component={Account} isPrivate />

          <Route
            path="/notificacoes"
            exact
            component={Notifications}
            isPrivate
          />

          <Route path="/seller" exact component={Suppliers} isPrivate />
          <Route path="/" exact component={Dashboard} isPrivate />
          <RouteDOM path="*" exact component={NotFoundPage} />
        </Switch>
      </Suspense>
    </AnimatePresence>
  );
};

export default Routes;
