import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { b64EncodeUnicode, b64DecodeUnicode } from '../utils/base64';
// const getToken = (): string | null => localStorage.getItem('@GIFTHY:Token');

const aws = axios.create(
  { 
    "timeout": 30000,
  }
);

aws.interceptors.request.use((config: AxiosRequestConfig) => {

  if (config.url?.includes('pagarme/cards') || config.url?.includes('pagarme/pix')) {
    const data = JSON.stringify(config.data);
    config.data = b64EncodeUnicode(data);  
  }

  return config;
});

export default aws;
