import React, { useEffect, useState } from 'react';

import { useRecoilValue } from 'recoil';
import { loadingStoreState } from '../../store/Loading/atom';

import spinner from '../../assets/imgs/gifthy-load.gif';

type LoadingProps = {
  active?: boolean;
};

const Loading: React.FC<LoadingProps> = ({ active = false }) => {
  const loadingStore = useRecoilValue(loadingStoreState);

  const [bodyHeight, setBodyHeight] = useState(0);
  useEffect(() => {
    if (loadingStore.active || active) {
      const { body } = document;
      const { documentElement } = document;

      const height = Math.max(
        body.scrollHeight,
        body.offsetHeight,
        documentElement.clientHeight,
        documentElement.scrollHeight,
        documentElement.offsetHeight,
      );
      setBodyHeight(height);
    }
  }, [loadingStore, active]);

  return (
    <>
      {(loadingStore.active || active) && (
        <div
          className="flex items-center flex-col w-full fixed top-0 left-0 z-1000 bg-gifthy-black-100 bg-opacity-90 pt-50vh"
          style={{ height: `${bodyHeight}px` }}
        >
          <img src={spinner} alt="Loading..." className="w-18 h-18 -ml-3" />

          <h1 className="text-base text-gifthy-blue font-extrabold py-2 tracking-widest">
            {`${loadingStore.info}`}
          </h1>
        </div>
      )}
    </>
  );
};

export default Loading;
