import React, { createContext, useContext, useCallback, useState } from 'react';

import ModalNofify from '../components/Notifications/modal';

export interface NewModalProps {
  action: boolean;
}

interface ModalObject {
  openModal(status: boolean): void;
  bodyModal: (status: React.ReactNode) => void;
}

const Modal = createContext<ModalObject>({} as ModalObject);

const NewModalProvider: React.FC = ({ children }) => {
  const [active, setActive] = useState(false);

  const [body, setBody] = useState(() => <h1>Modal Notify</h1>);

  const openModal = useCallback((status: boolean) => {
    setActive(status);
  }, []);

  const bodyModal = useCallback((content) => {
    setBody(content);
  }, []);

  return (
    <Modal.Provider value={{ openModal, bodyModal }}>
      {children}
      <ModalNofify
        action={active}
        handleCloseModal={(value) => openModal(value)}
      >
        {body}
      </ModalNofify>
    </Modal.Provider>
  );
};

function useModal(): ModalObject {
  const context = useContext(Modal);

  if (!context) {
    throw new Error('Notify must be used witnin an ModalProvider');
  }

  return context;
}

export { NewModalProvider, useModal };
