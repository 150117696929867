import React from 'react';

import { AuthProvider } from './auth';
import { ToastProvider } from './toast';
import { NewFriendsProvider } from './friends';
import { NewPixProvider } from './pix';
import { NewModalProvider } from './modal';
import { AddressNonexistentProvider } from './address';
import { HasNotifications } from './notification';
import Loading from '../components/Loading';

const AppProvider: React.FC = ({ children }) => {
  return (
    <AuthProvider>
      <ToastProvider>
        <NewFriendsProvider>
          <NewModalProvider>
            <AddressNonexistentProvider>
              <HasNotifications>
                <NewPixProvider>
                  <Loading />
                  {children}
                </NewPixProvider>
              </HasNotifications>
            </AddressNonexistentProvider>
          </NewModalProvider>
        </NewFriendsProvider>
      </ToastProvider>
    </AuthProvider>
  );
};

export default AppProvider;
