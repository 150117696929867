function convertToCents(value: number) {
  return value * 100;
}

const formatAmout = (value: number): string =>
  Intl.NumberFormat('pt-br', {
    style: 'currency',
    currency: 'BRL',
  }).format(value);

export default formatAmout;

export const BrazilianFormat = (value: number): string => { 
  return Intl.NumberFormat('pt-BR', { style: 'decimal', maximumFractionDigits: 2 }).format(value)
}