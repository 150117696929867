type WhichErrorsProps = {
  [index: string]: string;
};

export const getMessageError = (value = 'any error'): string => {
  const errors = {
    'invalid email': 'E-mail inválido',
    'invalid cpf': 'CPF inválido',
    'invalid password requirements':
      'Senha deve conter no mínimo 6 caracteres entre números e letras',
    'invalid password': 'Usuário e/ou senha inválido(s)',
    'Usuário com esse username não existe.': 'Usuário e/ou senha inválido(s)',
    'deactive user':
      'Conta do usuário não confirmada. Por favor, verifique seu email!',
    'record not found': 'Dado não encontrado',
    'unrecognized provider': 'Usuário não reconhecido',
    'token not accepted': 'Token não aceito',
    'token is expired': 'Token expirou, solicite outro!',
    'signature is invalid': 'Token inválido, solicite outro!',
    'this address not is your': 'Este endereço não é seu',
    'no products in the list': 'Não há produtos na lista',
    'type not accepted': 'Tipo não aceito',
    'guest already added': 'Convidado já adicionado',
    'the event is not yours': 'O evento não é seu',
    'product already bought': 'Produto já comprado',
    'higher than necessary': 'Maior que o necessário',
    "it's not a cow": 'Não é uma vaquinha',
    'me and my friend are the same person':
      'Eu e meu amigo somos a mesma pessoa',
    "we're already friends": 'Nós já somos amigos',
    'the notification is not yours': 'A notificação não é sua',
    'the notification has already been read': 'A notificação já foi lida',
    'wish already bought': 'Desejo já comprado',
    'this wish not is your': 'Este desejo não é seu',
    'auth not provided': 'Usuário não autorizado',
    'pq: duplicate key value violates unique constraint "uix_users_cpf"':
      'CPF já cadastrado',
    'ERROR: duplicate key value violates unique constraint "uix_users_cpf" (SQLSTATE 23505)':
      'CPF já cadastrado',
    'Já existe um usuário com esse cpf': 'CPF já cadastrado',
    'O campo "billing.document" deve conter um CPF ou CNPJ válido':
      'CPF inválido',
    'pq: duplicate key value violates unique constraint "uix_users_email"':
      'E-mail já cadastrado',
    'ERROR: duplicate key value violates unique constraint "uix_users_email" (SQLSTATE 23505)':
      'E-mail já cadastrado',
    'Já existe um usuário com esse email': 'E-mail já cadastrado',
    'json: cannot unmarshal string into Go struct field Gift.Quantity of type uint':
      'Formato do parâmetro Quantidade inválido',
    'pq: duplicate key value violates unique constraint "uix_gifts_uuid"':
      'Já comprou!',
    'Post "https://api.stripe.com/v1/payment_intents": x509: certificate signed by unknown authority':
      'Forma de pagamento está offline!',
    'x509: certificate signed by unknown authority':
      'Servidor de email está offline!',
    'Você não é amigo do dono desse evento.':
      'Você não é amigo do dono desse evento.',
    'Cannot read property "BoletoUrl" of null':
      'Problema na geração do Boleto!',
    'dont exist or is already active': 'Conta não existe ou já está ativada!',
    'dont exist': 'Dado não existe!',
    'is already active': 'Conta já está ativada!',
    'any error': 'Ocorreu um erro, por favor tente novamente',
    'sender address: record not found': 'Você não tem endereço cadastrado!',
    'could not found your address': 'Você não tem endereço cadastrado!',
    '501005': 'O número do cartão informado é inválido',
    'limit quantity has been reached': 'Quantidade limite foi atingida',
    COMMONS1028: 'Data de validade do cartão inválida',
    email: 'E-mail',
    password: 'Senha',
    cpf: 'CPF',
    nick_name: 'Apelido',
    show_year_of_birth: 'Exibir ano do nascimento',
    birthday: 'Aniversário',
    zip_code: 'CEP',
    province: 'Estado',
    city: 'Cidade',
    neighborhood: 'Setor / Bairro',
    street: 'Logradouro',
    number: 'Número',
    favorite: 'Favorito / Principal',
    name: 'Nome',
  } as WhichErrorsProps;

  return value;
};
