import React from 'react';

import { createBrowserHistory, History } from 'history';

import {
  Redirect,
  Route as ReactDOMRoute,
  RouteProps as ReactDOMRouteProps,
} from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { AssistantLarge } from '../components/Assistant';

// import { useAuth } from '../hooks/auth';
import { isLoggedStore } from '../store/User/selectors';

interface RouteProps extends ReactDOMRouteProps {
  isPrivate?: boolean;
  component: React.ComponentType;
  isBoth?: boolean;
  history?: History;
}

export const history = createBrowserHistory();

const Route: React.FC<RouteProps> = ({
  isPrivate = false,
  component: Component,
  isBoth = false,
  ...rest
}: RouteProps) => {
  // const { user } = useAuth();

  const isLogged = useRecoilValue(isLoggedStore);

  return (
    <ReactDOMRoute
      history={history}
      {...rest}
      render={({ location }) => {
        return isPrivate === isLogged || isBoth ? (
          <>
            <AssistantLarge />
            <Component />
          </>
        ) : (
          <Redirect
            to={{
              pathname: isPrivate ? '/login' : '/',
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
};

export default Route;
