/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react';

import * as Yup from 'yup';
import { useToast } from '../../hooks/toast';
import {
  IErrors,
  getValidationErrorsArray,
} from '../../utils/getValidationErrors';
import { getMessageError } from '../../utils/messageError';

interface ErrorsProps {
  title: string;
  errors: Yup.ValidationError | ErrorsResponseData | IErrors[] | string | null;
  time?: number;
}

interface ErrorsResponse {
  data: ErrorsData;
}

interface ErrorsData {
  error: string;
  field?: string;
  type?: string;
}

export class ErrorsResponseData extends Error {
  response: ErrorsResponse;

  constructor(response: ErrorsResponse) {
    super();
    this.response = response;
  }
}

const Errors: React.FC<ErrorsProps> = ({ title, errors, time = 3000 }) => {
  const { addToast } = useToast();

  useEffect(() => {
    if (errors) {
      const msg = [] as IErrors[];

      if (errors instanceof Yup.ValidationError) {
        const errs: IErrors[] = getValidationErrorsArray(errors);

        msg.push(
          {
            id: 'head',
            message: 'Preencha corretamente os campos abaixo: ',
          },
          ...errs,
        );
      } else if (typeof errors === 'string') {
        msg.push(
          {
            id: 'erro',
            message: 'Mensagem:',
          },
          {
            id: 'message',
            message: errors,
          },
        );
      } else if (Array.isArray(errors)) {
        msg.push(...errors);
      } else {
        let textMessage = 'Erro ao criar usuário.';

        if (errors.response) {
          const newErrors = errors.response.data;

          if (newErrors.field) {
            textMessage = `${getMessageError(newErrors.field)}. ${
              newErrors.error
            }`;
          } else if (newErrors.error.includes('juno')) {
            const juno = newErrors.error.replace(
              'juno/failed_response_from_api : ',
              '',
            );
            const junoError = JSON.parse(juno);

            textMessage = getMessageError(junoError.details[0].message);
          } else {
            try {
              const jsonError = JSON.parse(newErrors.error);

              const detailsMessage = jsonError.details[0].message;

              textMessage = detailsMessage;
            } catch {
              textMessage = getMessageError(newErrors.error);
            }
          }
        } else {
          try {
            const jsonError = JSON.parse(errors.message);

            textMessage = getMessageError(jsonError.errorCode);
          } catch {
            textMessage = getMessageError(errors.message);
          }
        }

        msg.push(
          {
            id: 'erro',
            message: 'Mensagem:',
          },
          {
            id: 'message',
            message: textMessage,
          },
        );
      }

      addToast({
        type: 'error',
        title,
        time,
        description: msg,
      });
    }
  }, [title, time, errors, addToast]);

  return null;
};

export default Errors;
