import React, { createContext, useContext, useCallback, useState } from 'react';

interface AddressNonexistentObject {
  show: boolean;
  setShowAddress(status: boolean): void;
}

const AddressNonexistent = createContext<AddressNonexistentObject>(
  {} as AddressNonexistentObject,
);

const AddressNonexistentProvider: React.FC = ({ children }) => {
  const [active, setActive] = useState(false);

  const setShowAddress = useCallback((status: boolean) => {
    setActive(status);
  }, []);

  return (
    <AddressNonexistent.Provider value={{ setShowAddress, show: active }}>
      {children}
    </AddressNonexistent.Provider>
  );
};

function useAddressNonexistent(): AddressNonexistentObject {
  const context = useContext(AddressNonexistent);

  if (!context) {
    throw new Error('address must be used within a Provider');
  }

  return context;
}

export { AddressNonexistentProvider, useAddressNonexistent };
