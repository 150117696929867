import React, { createContext, useContext, useCallback, useState } from 'react';

import NewFriends from '../components/Friends/new';

export interface NewFriendsProps {
  action: boolean;
}

interface FriendsObject {
  addFriend(status: boolean): void;
  removeFriend(status: boolean): void;
}

const Friends = createContext<FriendsObject>({} as FriendsObject);

const NewFriendsProvider: React.FC = ({ children }) => {
  const [active, setActive] = useState(false);

  const addFriend = useCallback((status: boolean) => {
    setActive(status);
  }, []);
  const removeFriend = useCallback((status: boolean) => {
    setActive(status);
  }, []);

  return (
    <Friends.Provider value={{ addFriend, removeFriend }}>
      {children}
      <NewFriends action={active} />
    </Friends.Provider>
  );
};

function useNewFriend(): FriendsObject {
  const context = useContext(Friends);

  if (!context) {
    throw new Error('useNewFriend must be used witnin an NewFriendsProvider');
  }

  return context;
}

export { NewFriendsProvider, useNewFriend };
