import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';

import Routes from './routes';
import AppProvider from './hooks';
import ScrollToTop from './routes/scroll';

const App: React.FC = () => {
  return (
    <RecoilRoot>
      <BrowserRouter forceRefresh>
        <AppProvider>
          <ScrollToTop>
            <Routes />
          </ScrollToTop>
        </AppProvider>
      </BrowserRouter>
    </RecoilRoot>
  );
};

export default App;
