import axios from 'axios';

import api from '../services/api';
import { TotalsDataProps } from '../interfaces';
import { NotificationsDataProp } from '../interfaces/notifications';
import {
  ShippingElementProps,
  ProvincesOptionsAPIDataProps,
} from '../interfaces/address';
import { SelectOptionsDataProps } from '../interfaces/select';

export const listNotifications = async (
  type: string,
): Promise<NotificationsDataProp[]> => {
  const response = await api.get<NotificationsDataProp[]>('/notifications');

  if (type !== '') {
    const lisNotification = response.data.filter(
      (notification) => notification.Type === type,
    );

    return lisNotification;
  }

  return response.data;
};

export const hasNotifications = async (): Promise<number> => {
  const response = await api.get<NotificationsDataProp[]>('/notifications');

  if (response.data.length > 0) {
    const list = response.data.filter((notification) => !notification.Read);

    return list.length;
  }

  return 0;
};

export const getImage = (id: string): string => {
  const url = process.env.REACT_APP_IMG_SRC;

  const image =
    `${id}`.includes('http') || `${id}`.includes('https')
      ? id
      : `${url}/images/${id}`;

  return image;
};

export const getTotals = async (id = 0): Promise<TotalsDataProps> => {
  const totals = await api.get(`${id ? `/statistics?id=${id}` : '/statistics'}`);

  return totals.data;
};

export const listProvinces = async (): Promise<SelectOptionsDataProps[]> => {
  const list = await axios.get(
    'https://servicodados.ibge.gov.br/api/v1/localidades/estados',
  );

  const provinces = list.data as ProvincesOptionsAPIDataProps[];

  const newProvince = provinces.map((province) => {
    return {
      id: province.sigla,
      value: province.nome,
      label: province.nome,
    };
  });

  return newProvince;
};

export const listCities = async (
  id: string,
): Promise<SelectOptionsDataProps[]> => {
  const list = await axios.get(
    `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${id}/municipios`,
  );

  const provinces = list.data as ProvincesOptionsAPIDataProps[];

  const newProvince = provinces.map((province) => {
    return {
      value: province.nome,
      label: province.nome,
    };
  });

  return newProvince;
};

export const getShipping = async (
  ProductID: number,
  Quantity: number,
  AddressID: number,
): Promise<ShippingElementProps[]> => {
  const shipping = await api.post(`/shipping`, {
    ProductID,
    Quantity,
    AddressID: Number(AddressID),
  });

  return shipping.data;
};
