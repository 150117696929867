/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react';

import Icons from '../SVG/Icons';

export interface ModalNofifyProps {
  action: boolean;
  handleCloseModal?: (value: boolean) => void;
}

const ModalNotfify: React.FC<ModalNofifyProps> = ({
  action,
  handleCloseModal = () => {},
  children,
}) => {
  return (
    <div
      className={`absolute top-0 left-0 ${
        action ? 'block' : 'hidden'
      } w-full h-screen z-900`}
    >
      <div className="flex items-center justify-center min-w-full min-h-full h-full fixed z-900">
        <div
          className="bg-gifthy-black-200 bg-opacity-20 w-full h-full absolute top-0 left-0 z-900"
          style={{
            backdropFilter: 'blur(2px)',
            WebkitBackdropFilter: 'blur(2px)',
          }}
        />

        <div className="bg-white w-full md:w-109-75 h-111-50 rounded-t-31 md:rounded-tl-11 md:rounded-bl-11 md:rounded-tr-none relative flex items-center flex-col py-10 z-1000">
          <button
            type="button"
            onClick={() => handleCloseModal(false)}
            className="w-8 h-8 rounded-full border-2 border-gifthy-blue bg-white flex items-center justify-center absolute top-0 -mt-4 md:right-0 md:-mr-4"
          >
            <Icons name="clear" className="w-5 h-5 text-gifthy-blue" />
          </button>

          <div className="px-4 md:px-8 py-4">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default ModalNotfify;
