import React, { createContext, useState, useCallback, useContext } from 'react';
import { hasNotifications } from '../data';

interface HasNotificationsObject {
  checkNotifications(): void;
  hasNotification: boolean;
}

const HasNotificationsContext = createContext<HasNotificationsObject>(
  {} as HasNotificationsObject,
);

const HasNotifications: React.FC = ({ children }) => {
  const [has, setHas] = useState(false);

  const checkNotifications = useCallback(async () => {
    await hasNotifications().then((result) => {
      setHas(!!result);
    });
  }, []);

  return (
    <HasNotificationsContext.Provider
      value={{ checkNotifications, hasNotification: has }}
    >
      {children}
    </HasNotificationsContext.Provider>
  );
};

function useHasNotifications(): HasNotificationsObject {
  const context = useContext(HasNotificationsContext);

  if (!context) {
    throw new Error('Notification must be used witnin an Provider');
  }

  return context;
}

export { HasNotifications, useHasNotifications };
