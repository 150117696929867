import React, { useMemo } from 'react';
import { useTransition } from 'react-spring';

import Toast from './Toast';

import { ToastMessage } from '../../hooks/toast';

interface ToastProps {
  messages: ToastMessage[];
  direction: string;
}

const ToastContainer: React.FC<ToastProps> = ({
  messages,
  direction = 'items-center',
}) => {
  const messagesWithTransations = useTransition(
    messages,
    (message) => message.id,
    {
      from: { top: '-300%', opacity: 0 },
      enter: { top: '0', opacity: 1 },
      leave: { top: '-300%', opacity: 0 },
    },
  );

  const style = useMemo(() => {
    return `${
      messages.length ? 'flex' : 'hidden'
    } flex-col ${direction} min-w-full fixed top-0 p-8 overflow-hidden z-1100`;
  }, [direction, messages]);

  return (
    <div className={style}>
      {messagesWithTransations.map(({ item, key, props }) => (
        <Toast key={key} style={props} message={item} />
      ))}
    </div>
  );
};

export default ToastContainer;
