import { atom } from 'recoil';
import {
  IAssistantBubbleProps,
  IAssistantSmallBubbleProps,
  IBubbleStepData,
} from '../../interfaces/assistant';

export const assistantStateStore = atom({
  key: 'assistantState',
  default: {
    active: false,
    color: {
      background: 'bg-gifthy-bubble-blue',
      heading: 'text-gifthy-bubble-orange-100',
      text: 'text-white',
    },
    link: {
      color: {
        background: 'bg-gifthy-bubble-orange-100',
        text: 'text-white',
      },
      text: {
        dashboard: 'Cadastrar endereço',
        account: 'Produtos',
        products: 'Escolher',
        detailsProduct: 'Encontrar amigos',
        friends: 'Pesquisar',
        events: 'Adicionar',
        newEvents: 'Preencha os dados',
        cow: 'Preencha os dados',
        selectProductsEvent: 'Selecione',
        selectProductsCow: 'Selecione',
      },
      url: '/minha-conta',
    },
  } as IAssistantBubbleProps,
});

export const headingBubbleStateStore = atom({
  key: 'headingBuubleState',
  default: {
    dashboard: 'Bem-vindo ao Gifthy!',
    account: 'Legal!',
    products: 'Produtos',
    desejos: 'Desejos',
    detailsProduct: 'Legal!',
    friends: 'Convide seus amigos!',
    events: 'Adicione seus eventos!',
    newEvents: 'Crie um evento',
    cow: 'Vamos fazer uma vaquinha!',
    selectProductsEvent: 'Defina os produtos',
    selectProductsCow: 'Defina os produtos',
  },
});

export const textBubbleStateStore = atom({
  key: 'textBuubleState',
  default: {
    dashboard:
      'Para começar, preencha seus dados de endereço, para que possamos saber onde entregaremos os seus presentes!',
    account:
      'Facilite a vida de quem gosta de você! Escolha o que você gostaria de ganhar de presente.',
    products:
      'Seleciona a categoria desejada, veja os destalhes do produto e adicione na sua lista',
    desejos:
      'Seleciona itens em sua lista de desejos, veja os destalhes do produto e adicione na sua lista',
    detailsProduct:
      'Depois de escolher os produtos que deseja, convide seus amigos!',
    friends:
      'Na barra de pesquisa você pode procurar seus amigos que já estão cadastrados no Gifthy ou poderá convidar quem ainda não faz parte',
    events:
      'Aqui você pode reunir a turma, marcar aquele churrasco, uma festa de aniversário, aquele chá de panela ou a festa de final de ano.',
    newEvents:
      'Preencha os detalhes do seu evento e selecione quantos presentes quiser para a lista do evento. Assim os participantes poderão escolher o que presentear.',
    cow:
      'Você pode criar uma vaquinha, para um desejo seu ou escolher entre os desejos no perfil dos seus amigos.',
    selectProductsEvent:
      'Escolha a categoria e depois assinale os produtos que farão parte da sua lista do seu evento.',
    selectProductsCow:
      'Escolha a categoria e depois assinale os produtos que farão parte da sua lista do seu evento.',
  },
});

export const bubbleStepStateStore = atom({
  key: 'BubbleStepState',
  default: {
    step: 'dashboard',
  } as IBubbleStepData,
});

export const assistantSmallStateStore = atom({
  key: 'assistantSmallState',
  default: {
    active: false,
    color: {
      background: 'bg-gifthy-bubble-orange',
      heading: 'text-white',
    },
    heading: 'Precisa de ajuda?',
    button: {
      color: {
        background: 'bg-gifthy-bubble-blue',
        text: 'text-white',
      },
      text: 'Assistente',
    },
  } as IAssistantSmallBubbleProps,
});

export const nameLargeAssistantStateStore = atom({
  key: 'nameLargeAssistantState',
  default: '',
});

export const nameSmallAssistantStateStore = atom({
  key: 'nameSmallAssistantState',
  default: '',
});

export const focusSearchFriendsStateStore = atom({
  key: 'focusSearchFriendsState',
  default: false,
});
